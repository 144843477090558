import React, { Component } from "react";
import { FiAperture , FiImage , FiCamera , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiAperture />,
        title: 'Fotoestudio',
        description: 'Ya sea para tramite o diversion, fotografiamos tu mejor angulo.'
    },
    {
        icon: <FiImage />,
        title: 'Impresion',
        description: 'Inmortaliza tus momentos con fotografias impresas.'
    },
    {
        icon: <FiMonitor />,
        title: 'Diseño Grafico',
        description: 'Mejoramos o adaptamos tus fotografias e imagenes como las desees. '
    },
    {
        icon: <FiCamera />,
        title: 'Fotografia de Eventos',
        description: 'Capturamos los mejores momentos con equipo de calidad y fotografos entrenados.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Servicios',
        description = 'Te ofrecemos servicios de calidad con el más alto grado de profesionalismo.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Mas sobre nuestros servicios</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
