import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                EN COLABORACION CON:
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-01.png" alt="Canon"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-02.png" alt="Silibrain"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="Mitsubishi"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Sinfonia"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="Luszol"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;