import React, { Component } from "react";

class AboutTwo extends Component{
    render(){
        let title = '¿Quiénes somos?',
        description = 'En los años 80 en la ciudad de Guatemala, nace “FOTOLAB” como una empresa con el objetivo de brindar servicios fotográficos profesionales de la más alta calidad a los guatemaltecos.Desde sus inicios, la empresa se embarcó en la misión de permitir a las familias guatemaltecas capturar sus recuerdos más memorables con las mejores fotografías. Con el pasar de los años FOTOLAB se expande, integrando a sus servicios la venta de productos reconocidos. Poco a poco, la empresa abre un número de tiendas a nivel regional con el afán de poder brindarle sus servicios y productos a más guatemaltecos. Siempre conservadores y reconocidos en el mercado, FOTOLAB se ha mantenido a la vanguardia de la tecnología, imprimiendo en los corazones de sus clientes cariño y un poco de nostalgia al facilitarle a sus clientes el recordar y volver a vivir todos aquellos momentos tan especiales de sus vidas.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-3.jpg" alt="Fotolab photographer"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutTwo;