import React, { Component } from "react";
import * as Amplitude from '@amplitude/node';
import PageHelmet from "../component/common/Helmet";
import { FiPhone , FiMail , FiMapPin, FiClock } from "react-icons/fi";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import WhatsAppWidget from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

const client = Amplitude.init('10124a810cfc12f258a5f4b2d869c9a4');

let ip = require("ip");

client.logEvent({
  event_type: 'Contact Page Hits',
  user_id: '@fotolab.com.gt',
  ip: ip.address()
});


class Contact extends Component{

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contactanos' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Hablanos, juro que no mordemos...</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Oficinas Centrales</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 22590800">+502 2259-0800</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:hola@fotolab.com.gt">hola@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - VIE : 8:00 - 17:00</p>
                                        <p>SAB : 8:00 - 12:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>4ta Calle Zona 10</p>
                                        <p>Ciudad de Guatemala</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}


                 {/* Start Contact Top Area  */}
                 <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Atanasio Tzul</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 24778727">+502 2477-6727</a></p>
                                        <WhatsAppWidget phoneNumber='50258448396' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Atanasio' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:atanasio@fotolab.com.gt">atanasio@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 9:00 - 20:00</p>
                                        <p>DOM : 10:00 - 19:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>Calz. Atanasio Tzul 51-57 Z. 12 Local 112 </p>
                                        <p>C.C. Atanasio Tzul, Ciudad de Guatemala</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                 {/* Start Contact Top Area  */}
                 <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Av. Centroamerica</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 2230 1417">+502 2230-1417</a></p>
                                        <WhatsAppWidget phoneNumber='50258428986' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Ave. C.A.' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:aveca@fotolab.com.gt">aveca@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - VIE : 8:00 - 18:00</p>
                                        <p>SAB : 8:00 - 13:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>Av.Centro America, 15-45, Zona 1</p>
                                        <p>Ciudad de Guatemala</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Coatepeque, Quetzaltenango</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 77755801">+502 7775-5801</a></p>
                                        <WhatsAppWidget phoneNumber='50258415956' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Coatepeque' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:coatepeque@fotolab.com.gt">coatepeque@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                           {/* Start Single Address  */}
                           <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 8:00 - 19:00</p>
                                        <p>DOM : 8:00 - 12:30</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>4a. Av. 4-43, Zona 1</p>
                                        <p>Coatepeque, Quetzaltenango</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Parque La Concordia, Centro Historico</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 22533448">+502 2253-3448</a></p>
                                        <WhatsAppWidget phoneNumber='50236479256' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Concordia' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:concordia@fotolab.com.gt">concordia@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 8:00 - 18:00</p>
                                        <p>SAB - DOM : 8:00 - 13:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>14 Calle 4-48, Zona 1</p>
                                        <p>Ciudad de Guatemala</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Escuintla, Escuintla</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 78880634">+502 7888-0634</a></p>
                                        <WhatsAppWidget phoneNumber='50258376658' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Escuintla' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:escuintla@fotolab.com.gt">escuintla@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                           {/* Start Single Address  */}
                           <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 8:00 - 18:00</p>
                                        <p>DOM : 8:00 - 12:30</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>4a.Avenida 5-13, Zona 1</p>
                                        <p>Escuintla, Escuintla</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Mazatenango</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 78723544">+502 7872-3544</a></p>
                                        <WhatsAppWidget phoneNumber='50258383459' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Mazate' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:mazate@fotolab.com.gt">mazate@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                          {/* Start Single Address  */}
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 8:00 - 19:00</p>
                                        <p>DOM : 8:00 - 13:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>7a. Calle 4-16 Zona 1</p>
                                        <p>Mazatenango, Suchitepequez</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Centro Comercial Megacentro</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 24728700">+502 2472-8700</a></p>
                                        <WhatsAppWidget phoneNumber='50258441391' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Megacentro' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:megacentro@fotolab.com.gt">megacentro@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                           {/* Start Single Address  */}
                           <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 9:00 - 20:00</p>
                                        <p>DOM : 10:00 - 19:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>Calz. Roosevelt 29-60, Zona 7 Local 21</p>
                                        <p>C.C.Megacentro, Ciudad de Guatemala</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Centro Comercial Metronorte</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 22563702">+502 2256-3702</a></p>
                                        <WhatsAppWidget phoneNumber='50258449854' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Metronorte' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:metronorte@fotolab.com.gt">metronorte@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                           {/* Start Single Address  */}
                           <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - DOM : 10:00 - 20:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>Km. 5 carretera al Atlan. Z. 17 Local 59-60</p>
                                        <p>C.C. Metronorte, Ciudad de Guatemala</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Centro Comercial Pradera Z.10 </h3>                  
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 23683087">+502 2368-3087</a></p>
                                        <WhatsAppWidget phoneNumber='50258434224' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Pradera' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:pradera@fotolab.com.gt">pradera@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 10:00 - 20:00</p>
                                        <p>DOM : 10:00 - 19:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>Blvd.Los Proceres, 20 Calle 25-85 Zona 10, 3er.N. Local 335</p>
                                        <p>C.C.Pradera, Ciudad de Guatemala</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Quetzaltenango</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 77653800">+502 7765-3800</a></p>
                                        <WhatsAppWidget phoneNumber='50258443564' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Xela' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:xelaparque@fotolab.com.gt">xelaparque@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 8:00 - 19:00</p>
                                        <p>DOM : 8:00 - 13:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>4a. Calle 12-22, Zona 1</p>
                                        <p>Quetzaltenango, Quetzaltenango</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Retalhuleu, Retalhuleu</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 77714729">+502 7771-4729</a></p>
                                        <WhatsAppWidget phoneNumber='50258371522' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Reu' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:reu@fotolab.com.gt">reu@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 8:00 - 19:00</p>
                                        <p>DOM : 8:00 - 13:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>5a.Avenida A 4-97, Zona 1</p>
                                        <p>Retalhuleu, Retalhuleu</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>San Pedro, San Marcos</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 77607694">+502 7760-7694</a></p>
                                        <WhatsAppWidget phoneNumber='50258427897' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab San Pedro' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:sanpedro@fotolab.com.gt">sanpedro@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 8:00 - 19:00</p>
                                        <p>DOM : 9:00 - 13:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>4a.Calle 5-49, Zona 1. San Germán</p>
                                        <p>San Pedro, San Marcos</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Santa Elena, Petén</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 79264050">+502 7926-4050</a></p>
                                        <WhatsAppWidget phoneNumber='50258379381' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Santa Elena' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:santaelena@fotolab.com.gt">santaelena@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 8:00 - 19:00</p>
                                        <p>DOM : 8:00 - 14:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>Calle Principal Santa Elena</p>
                                        <p>Flores, Peten</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Santa Lucía Cotz.</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 78826752">+502 7882-6752</a></p>
                                        <WhatsAppWidget phoneNumber='50258382194' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Santa Lucia' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:santalucia@fotolab.com.gt">santalucia@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 8:00 - 18:00</p>
                                        <p>DOM : 9:00 - 14:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>4a. Calle 2-47, Zona 1</p>
                                        <p>Santa Lucia Cotzumalguapa, Escuintla</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* End Contact Top Area  */}

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <h3>Centro Comercial Metroncentro, Villa Nueva</h3>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Llamanos</h4>
                                        <p><a href="tel:+502 45520441">+502 4552-0441</a></p>
                                        <WhatsAppWidget phoneNumber='50258437157' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Fotolab Villa Nueva' sendButton='Enviar'/>  
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Escribenos</h4>
                                        <p><a href="mailto:villanueva@fotolab.com.gt">villanueva@fotolab.com.gt</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiClock />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Horarios</h4>
                                        <p>LUN - SAB : 9:00 - 20:00</p>
                                        <p>DOM : 10:00 - 19:00</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                             {/* Start Single Address  */}
                             <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicacion</h4>
                                        <p>0 calle 16-20, Zona 4 Local 211</p>
                                        <p>C.C. Metrocentro Villa Nueva, Guatemala</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}
                

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Contact