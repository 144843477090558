import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PortfolioList from "../elements/portfolio/PortfolioList";


const Portfolio = () => {
    return (
        <>
            <PageHelmet pageTitle='Productos' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Productos'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">


                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">DE TODO EN FOTOGRAFIA</h2>
                                        <p>Brindamos a nuestros clientes una alta variedad de productos fotográficos al mejor precio del mercado, con marcas reconocidas a nivel mundial siendo Canon nuestro principal aliados para brindarle a nuestros clientes productos novedosos de alta calidad al mejor precio del mercado con lo mas nuevo en diseños, tecnología de punta.</p>                                    
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-6 col-md-6 col-sm-6 col-12" item="4" />
                            </div>
                            <div>
                            <h1></h1>
                                <p>Te ofrecemos una variedad de cámaras fotográficas tanto profesionales como compactas, con altas resoluciones, acceso a internet, video full HD, compatibles con equipos de computación, variedad de estilos y colores marcas reconocidas que te brindaran la seguridad necesaria para volverte un fotógrafo profesional o semi-profesional al alcance de tu bolsillo.</p>
                                <p>Variedad de baterías en diferentes voltajes AA,AAA, 9 voltios, alcalinas y recargables con su respectivo cargador para que no te quedes sin pila.</p>
                                <p>Encontraras variedad de accesorios fotográficos como  papel, rollos, trípodes, impresoras, lentes, flash, correas, estuches, maletines, memorias para que tengas tu equipo fotográfico con los mejores y novedosos accesorios que todo fotógrafo debe tener.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}


            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default Portfolio;