import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCamera, FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const ServiceList = [
    {
        icon: <FiCamera />,
        title: 'Fotografia',
        description: 'Fotos de eventos o estudio para deslumbrar.'
    },
    {
        icon: <FiMonitor />,
        title: 'Diseño',
        description: 'Diseños graficos para plasmar tu imaginacion en imagen.'
    }
]

class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Servicios' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Servicios'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Mas de 30 años en la vanguardia de la fotografia </h2>
                                    <p>Te brindamos nuestra experiencia como servicios de la mas alta calidad.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Fotografia</h2>
                                    <p>Te ofrecemos un servicio de calidad con el más alto grado de profesionalismo.</p>
                                    <p>Te brindamos el servicio de foto estudio profesional con los mejores diseños, fondos, marcos, leyendas, a tu elección plasmando tus más bellos recuerdos en una foto en diferentes tamaños como 8*10, 11*14, 12*18, para que queden enmarcados no solo en tu mente y corazón, sino en una linda fotografía.</p>
                                </div>
                                <img className="w-100" src={`/assets/images/service/foto.png`} alt="Fotografia"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area */}
                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                }}/>
                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Diseño</h2>
                                    <p>Te editamos esos lindos detalles en un solo lugar, con el estilo que más te guste utilizando diseños novedosos, artísticos y todo al alcance de tu imaginación.</p>
                                    <p>Inspírate con elegantes diseños personalizados para ti, para transformar las fotos que te gustan en fotos que amarás.</p>
                                    <p>Creamos tus imágenes perfectas.</p>
                                </div>
                                <img className="w-100" src={`/assets/images/service/diseno.png`} alt="Diseno"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;